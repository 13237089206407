<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="" cols="1"></v-col>
        <v-col class="d-flex ml-n12 flex-column py-0" cols="5">
          <s-text weight="medium" color="gray" size="sm">
            Subscription
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Amount
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            Date Sent
          </s-text>
        </v-col>
        <v-col class="action_group d-flex align-center"></v-col>
      </v-row>
      <v-row
        v-for="log in subscriptions"
        :key="log.id"
        class="pa-0 ma-0 mb-3 app-card"
        @click="openSubscriptionDetails(log)"
      >
        <v-col class="" cols="1">
          <v-icon v-if="getSubscrptionStatus(log.status)" color="green">mdi-check</v-icon>
        </v-col>
        <v-col class="d-flex ml-n12 flex-column" cols="5">
          <s-text
            weight="medium"
            color="grayTextAlt"
          >
            {{ log.type }}
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.currency }}{{ getCurrencyFormatted(log.amount) }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <div>
            {{ log.created_at ? getHumanReadableDateShort(log.created_at) : "-" }}
          </div>
        </v-col>
        <v-col class="action_group d-flex align-center flex-row-reverse">
          <s-icon name="arrow-circle-right" />
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showModal"
      persistent
      isModal
      title="Subscription Details"
    >
      <view-subscription-details
        v-if="showModal"
        :id="id"
        :sub-id="subId"
        @close="showModal = false"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewSubscriptionDetails from '@/components/apps/ViewSubscriptionDetails'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDateShort } from '@/utils/date'
import { getCurrencyFormatted } from '@/utils/fmt'
export default {
  name: 'AppSubscriptions',
  components: {
    loader: Loader,
    'view-subscription-details': ViewSubscriptionDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    subId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      subscriptions: 'user/currentUserSubscriptions',
    }),
  },
  data () {
    return {
      isLoading: false,
      showModal: this.$route.meta.showModal,
      log: null,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCurrencyFormatted,
    getSubscrptionStatus (status) {
      return (status === 'Successful')
    },
    getSupscriptions () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserSubscriptions', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
    openSubscriptionDetails (sub) {
      this.$router.push({ name: 'appSubscriptionDetail', params: { id: this.id, subId: sub.id } })
    },
  },
  watch: {
    '$route.meta' ({ showModal }) {
      this.showModal = showModal
    },
    subscriptions: {
      handler () {
        if (this.subscriptions) return
        this.getSupscriptions()
      },
      immediate: true,
    },
  },
}
</script>
