<template>
  <div class="px-9 my-5">
    <loader
      v-if="isLoading"
      message="Loading ...."
    />
    <div v-else>
      <v-list-item-group class="mb-5">
        <v-list-item-content class="py-4 pl-0">
          <v-col class="pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Subscrption Type</s-text>
            <s-text weight="bold" size="lg" color="black">
              {{ subscription.type || 'Unknown type' }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content class="py-4 pl-0 top-thin-border">
          <v-col class="pl-0">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Txn Reference</s-text>
            <s-text weight="bold" size="md" color="black">
              {{ subscription.txn_ref || 'Unknown' }}
            </s-text>
          </v-col>
          <v-col class="pl-0" v-if="subscription.bot_ref">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Bot Reference</s-text>
            <s-text weight="bold" size="md" color="black">
              {{ subscription.bot_ref }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content class="py-4 pl-0 top-thin-border" v-if="subscription.meta.p2p_transfer">
          <v-col class="pl-0" cols="6">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">From</s-text>
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: subscription.meta.p2p_transfer.src_user_id } }"
            >
              {{ subscription.meta.p2p_transfer.src_user_id }}
            </s-link>
          </v-col>
          <v-col class="pl-0" cols="6">
            <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">To</s-text>
            <s-link
              weight="medium"
              color="grayTextAlt"
              :to="{ name: 'appDetails', params: { id: subscription.meta.p2p_transfer.dst_user_id } }"
            >
              {{ subscription.meta.p2p_transfer.dst_user_id }}
            </s-link>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Amount
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ subscription.currency }} {{ getCurrencyFormatted(subscription.amount) }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Status
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ subscription.status || "Unknown" }}
            </s-text>
          </v-col>
        </v-list-item-content>
        <v-list-item-content :ripple="false" class="top-thin-border">
          <v-col class="pl-0">
            <s-text
              weight="normal"
              size="sm"
              color="grayTextAlt"
              class="mb-4"
            >
              Created At
            </s-text>
            <s-text size="md-m" weight="bold">
              {{ subscription.created_at ? getHumanReadableDateShort(subscription.created_at) : "-" }}
            </s-text>
          </v-col>
        </v-list-item-content>
      </v-list-item-group>
      <div v-if="subscription.events" class="mb-8 top-thin-border pt-4">
        <s-text
          weight="normal"
          size="sm"
          color="grayTextAlt"
          class="mb-4"
        >
          Transaction Events
        </s-text>
        <v-container class="pa-0">
          <v-row class="pa-1 ma-0 my-1" style="background: #f9f9f9; border-radius: 8px;">
            <v-col class="d-flex flex-column py-0" cols="4">
              <s-text weight="medium" color="gray" size="sm">
                Type
              </s-text>
            </v-col>
            <v-col class="d-flex flex-column py-0" cols="4">
              <s-text weight="medium" color="gray" size="sm">
                Message
              </s-text>
            </v-col>
            <v-col class="d-flex flex-column py-0" cols="4">
              <s-text weight="medium" color="gray" size="sm">
                Timestamp
              </s-text>
            </v-col>
          </v-row>
          <v-row
            v-for="event in subscription.events"
            :key="event.timestamp"
            class="pa-0 ma-0 mb-1 app-card app-card-content"
            style="background: #fff;"
          >
            <v-col class="d-flex flex-column" cols="4">
              <div>
                {{ event.event }}
              </div>
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <div>
                <truncate-text
                  :text="event.msg"
                />
              </div>
            </v-col>
            <v-col class="d-flex flex-column" cols="4">
              <div>
                {{ event.timestamp ? getHumanReadableDateShort(event.timestamp) : "-" }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <s-btn
        @click="goBack"
        type="button"
        color="primary"
        elevation="0"
      >
        OK
      </s-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHumanReadableDateShort } from '@/utils/date'
import { getCurrencyFormatted } from '@/utils/fmt'
export default {
  name: 'ViewSubscriptionDetails',
  props: {
    id: {
      type: String,
      required: true,
    },
    subId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      subscriptions: 'user/currentUserSubscriptions',
      currentUserSubscriptionForId: 'user/currentUserSubscriptionForId',
    }),
    subscription () {
      return this.currentUserSubscriptionForId(this.subId)
    },
  },
  data () {
    return {
      isLoading: true,
    }
  },
  methods: {
    getHumanReadableDateShort,
    getCurrencyFormatted,
    goBack () {
      this.$router.go(-1)
    },
  },
  watch: {
    subscriptions: {
      handler () {
        if (this.subscriptions) {
          this.isLoading = false
        }

        this.isLoading = false
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
